import './src/Estilos/style.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';


let lgpdHtml = `
<div class="lgpd">
    <div class="lgpd-left">
        <p class="texto-lgpd"> 
            Utilizamos Cookies para oferecer a melhor experiência possível em nosso Site.
        </p>
        <a class="saiba-lgpd" href="https://privacidade.digitalid.com.br/" target="blank">Politicas de privacidade</a>
        <div class="lgpd-right">
            <button>Aceitar</button>
        </div>
    </div>
</div>
`
let lsContent = localStorage.getItem('lgpd');
if (!lsContent ) {
document.body.innerHTML += lgpdHtml;

let lgpdArea = document.querySelector('.lgpd');
let lgpdbutton = lgpdArea.querySelector('button');

lgpdbutton.addEventListener('click', () => {

    lgpdArea.remove()
        localStorage.setItem('lgpd', 'Usuario')
})
}